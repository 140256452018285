<template>
  <v-container fluid class="grey lighten-5">
    <v-row class="py-12">
      <v-col cols="12">
        <h1 class="primary--text text--darken-1 text-center">
          Soporte Empresarial Tidingo
        </h1>
      </v-col>
    </v-row>

    <v-row align="center" justify="center">
      <v-col cols="12" lg="3" xl="2">
        <v-card
          min-height="200"
          class="ml-1 mt-1 mb-1 mr-1 rounded-xl"
          outlined
          max-height="200"
        >
          <div class="d-flex justify-center align-center flex-column">
            <v-card-title>
              <v-avatar size="70" color="secondary" class="mt-5 mb-3">
                <v-icon x-large class="icon-size white--text"
                  >mdi-scale
                </v-icon>
              </v-avatar>
            </v-card-title>
            <v-card-text>
              <p class="text-center black--text">Asesoría legal</p>
            </v-card-text>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" lg="3" xl="2">
        <v-card
          min-height="200"
          class="ml-1 mt-1 mb-1 mr-1 rounded-xl"
          outlined
          max-height="200"
        >
          <div class="d-flex justify-center align-center flex-column">
            <v-card-title>
              <v-avatar size="65" color="secondary" class="mt-5 mb-3">
                <v-icon x-large class="icon-size white--text"
                  >mdi-calculator
                </v-icon>
              </v-avatar>
            </v-card-title>
            <v-card-text>
              <p class="text-center black--text">Asesoría físcal</p>
            </v-card-text>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" lg="3" xl="2">
        <v-card
          min-height="200"
          class="ml-1 mt-1 mb-1 mr-1 rounded-xl"
          outlined
          max-height="200"
        >
          <div class="d-flex justify-center align-center flex-column">
            <v-card-title>
              <v-avatar size="65" color="secondary" class="mt-5 mb-3">
                <v-icon x-large class="icon-size white--text"
                  >mdi-headphones</v-icon
                >
              </v-avatar>
            </v-card-title>
            <v-card-text>
              <p class="text-center black--text">
                Soporte técnico para la atención de clientes
              </p>
            </v-card-text>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" lg="3" xl="2">
        <v-card
          min-height="200"
          class="ml-1 mt-1 mb-1 mr-1 rounded-xl"
          outlined
          max-height="200"
        >
          <div class="d-flex justify-center align-center flex-column">
            <v-card-title>
              <v-avatar size="65" color="secondary" class="mt-5 mb-3">
                <v-icon x-large class="icon-size white--text"
                  >mdi-code-braces-box
                </v-icon>
              </v-avatar>
            </v-card-title>
            <v-card-text>
              <p class="text-center black--text">
                Soporte del área de desarrollo
              </p>
            </v-card-text>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="pb-6 pt-6" align="center" justify="center">
      <v-col cols="12" lg="3" xl="2">
        <v-card
          min-height="200"
          class="ml-1 mt-1 mb-1 mr-1 rounded-xl"
          outlined
          max-height="200"
        >
          <div class="d-flex justify-center align-center flex-column">
            <v-card-title>
              <v-avatar size="65" color="secondary" class="mt-5 mb-3">
                <v-icon x-large class="icon-size white--text"
                  >mdi-cellphone-arrow-down</v-icon
                >
              </v-avatar>
            </v-card-title>
            <v-card-text>
              <p class="text-center black--text">
                Actualizaciones constantes de nuestros productos
              </p>
            </v-card-text>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" lg="3" xl="2">
        <v-card
          min-height="200"
          class="ml-1 mt-1 mb-1 mr-1 rounded-xl"
          outlined
          max-height="200"
        >
          <div class="d-flex justify-center align-center flex-column">
            <v-card-title>
              <v-avatar size="65" color="secondary" class="mt-5 mb-3">
                <v-icon x-large class="icon-size white--text"
                  >mdi-human-greeting-proximity</v-icon
                >
              </v-avatar>
            </v-card-title>
            <v-card-text class="pb-6">
              <p class="text-center black--text">
                Software de gestión de clientes conectado a soporte técnico y
                desarrollo
              </p>
            </v-card-text>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" lg="3" xl="2">
        <v-card
          min-height="200"
          class="ml-1 mt-1 mb-1 mr-1 rounded-xl"
          outlined
          max-height="200"
        >
          <div class="d-flex justify-center align-center flex-column">
            <v-card-title>
              <v-avatar size="65" color="secondary" class="mt-5 mb-3">
                <v-icon x-large class="icon-size white--text"
                  >mdi-shopping</v-icon
                >
              </v-avatar>
            </v-card-title>
            <v-card-text>
              <p class="text-center black--text">
                Ecommerce de nuestros propios productos
              </p>
            </v-card-text>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<style scoped>
.icon-size {
  height: 40px;
}
</style>
<script>
import SvgIcon from "@jamescoyle/vue-icon";
import {
  mdiSeal,
  mdiRadar,
  mdiThumbUpOutline,
  mdiHandHeartOutline,
} from "@mdi/js";

export default {
  name: "OurValue",
  components: {
    SvgIcon,
  },
  data: () => ({
    sealPath: mdiSeal,
    radarPath: mdiRadar,
    thumbPath: mdiThumbUpOutline,
    handPath: mdiHandHeartOutline,
  }),
  computed: {
    getIconSize() {
      switch (this.$vuetify.breakpoint.mdAndUp) {
        case "xs":
          return 24;
        case "sm":
          return 30;
        case "md":
          return 40;
        default:
          return 60;
      }
    },
  },
};
</script>
